import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';

type FeedResponse = {
  items: Array<{
    title: string;
    url: string;
    date_modified: string;
  }>;
};

export const useDashboardFeed = (): UseQueryResult<AxiosResponse<FeedResponse>, unknown> => {
  return useQuery({
    queryKey: ['dashboard-feed'],

    queryFn: async () =>
      await axios.get<FeedResponse>('https://rocket.net/blog/category/product-updates/feed/json/'),
  });
};
